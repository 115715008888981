


























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import EmptyIllustration from '@/app/ui/assets/EmptyState/no_filtered_data.vue'
import controller from '@/app/ui/controllers/ManageDataPayrollController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import { Utils } from '@/app/infrastructures/misc'
import {
  ManageDataPayrollDetail,
  ManageDataPayrollHistory,
} from '@/domain/entities/ManageDataPayroll'
import {
  EnumAdjusted,
  EnumMode,
  EnumTable,
} from '@/app/infrastructures/misc/Constants/manageDataPayroll'

interface IDateRange {
  start: string
  end: string
  shortcut: string
}

interface IOptions {
  label: string
  value: string | boolean | number
}

interface IParams {
  page: number
  per_page: number
}

interface IDetailParams extends IParams {
  mode: IOptions
  has_adjustment: IOptions
  date_range: IDateRange | null
  time_zone: string
}

interface IStyle {
  minWidth: string
  maxWidth: string
  wordBreak?: string
}

interface IDataCell {
  value: number | string
  customStyle: IStyle
}

interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

@Component({
  components: {
    DataTableV2,
    DateTimePicker,
    DropdownSelect,
    PaginationNav,
    EmptyIllustration,
    Loading,
    Badge,
  },
})
export default class ManageDataPayrollDetailPage extends Vue {
  controller = controller
  enumTable = EnumTable

  currentDate = dayjs()
    .locale(dayjsID)
    .format('YYYY-MM-DD')

  customDateShortcuts = [
    { key: 'chooseDate', label: 'Tentukan Tanggal', value: 0 },
    { key: 'last7Days', label: '7 Hari Terakhir', value: 7 },
    { key: 'last14Days', label: '14 Hari Terakhir', value: 14 },
    { key: 'last30Days', label: '30 Hari Terakhir', value: 30 },
  ]

  modeFilterOptions: Array<IOptions> = [
    { label: 'Semua', value: '' },
    { label: 'Pick Up', value: EnumMode.PICKUP },
    { label: 'Delivery', value: EnumMode.DELIVERY },
  ]

  penyesuaianFilterOptions: Array<IOptions> = [
    { label: 'Semua', value: '' },
    { label: 'Ya', value: true },
    { label: 'Tidak', value: false },
  ]

  paginationOptions: Array<IOptions> = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  detailParameters: IDetailParams = {
    page: 1,
    per_page: Number(this.paginationOptions[0].value),
    mode: this.modeFilterOptions[0],
    has_adjustment: this.penyesuaianFilterOptions[0],
    date_range: null,
    time_zone: dayjs()
      .locale(dayjsID)
      .format('Z'),
  }

  historyParameters: IParams = {
    page: 1,
    per_page: Number(this.paginationOptions[0].value),
  }

  detailTableHeader: Array<IHeaderCell> = [
    this.headerCellMapper('No.', '10%'),
    this.headerCellMapper('Mode', '10%'),
    this.headerCellMapper('Shipment ID / STT', '20%'),
    this.headerCellMapper('Date time', '20%'),
    this.headerCellMapper('Jumlah Dana', '15%'),
    this.headerCellMapper('Penyesuaian', '25%'),
  ]

  historyTableHeader: Array<IHeaderCell> = [
    this.headerCellMapper('No.', '60px'),
    this.headerCellMapper('Date Time', '220px'),
    this.headerCellMapper('Jumlah Tarik', '160px'),
    this.headerCellMapper('Biaya Admin', '140px'),
    this.headerCellMapper('Saldo Masuk', '180px'),
    this.headerCellMapper('Tarik Ke', '300px'),
    this.headerCellMapper('Status', '144px'),
    this.headerCellMapper('Keterangan', '280px'),
  ]

  detailData: Array<Array<IDataCell>> = []
  historyData: Array<Array<IDataCell>> = []

  created(): void {
    this.fetchManageDataPayrollDetail()
    this.fetchManageDataPayrollHistory()
  }

  get detailParams(): Record<string, string | number | boolean> {
    const res: Record<string, string | number | boolean> = {
      page: this.detailParameters.page,
      per_page: this.detailParameters.per_page,
      time_zone: encodeURIComponent(this.detailParameters.time_zone),
    }

    if (this.detailParameters.mode.value !== '') {
      res.mode = this.detailParameters.mode.value
    }

    if (this.detailParameters.has_adjustment.value !== '') {
      res.has_adjustment = this.detailParameters.has_adjustment.value
    }

    if (
      this.detailParameters.date_range?.start &&
      this.detailParameters.date_range?.end
    ) {
      res.date_from = this.detailParameters.date_range.start
      res.date_to = this.detailParameters.date_range.end
    }

    return res
  }

  get historyParams(): Record<string, string | number> {
    return { ...this.historyParameters }
  }

  private fetchManageDataPayrollDetail(reset?: boolean): void {
    if (reset) this.detailParameters.page = 1
    const payload = {
      id: Number(this.$route.params.id),
      params: this.detailParams,
    }
    controller.getAllDetail(payload)
  }

  private fetchManageDataPayrollHistory(reset?: boolean): void {
    if (reset) this.historyParameters.page = 1
    const payload = {
      id: Number(this.$route.params.id),
      params: this.historyParams,
    }
    controller.getAllHistory(payload)
  }

  private onFilterDetailList = Utils.debounce(() => {
    this.fetchManageDataPayrollDetail(true)
  }, 400)

  private onDateFilter(): void {
    if (
      this.detailParameters.date_range?.start &&
      !this.detailParameters.date_range?.end
    ) {
      return
    }

    this.onFilterDetailList()
  }

  private tableCellMapper(value: string | number, colWidth: string): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        wordBreak: 'break-word',
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private onChangePaginationOption(
    perPageAmount: number,
    table: EnumTable.DETAIL_TABLE | EnumTable.HISTORY_TABLE
  ): void {
    if (table === EnumTable.DETAIL_TABLE) {
      this.detailParameters.per_page = perPageAmount
      this.fetchManageDataPayrollDetail(true)
    } else if (table === EnumTable.HISTORY_TABLE) {
      this.historyParameters.per_page = perPageAmount
      this.fetchManageDataPayrollHistory(true)
    }
  }

  private formatDateTime(dateTime: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(dateTime, 'DD MMMM YYYY, HH:mm Z')
    )
  }

  private indexTable(idx: number, page: number, perPage: number): string {
    const getCountData = (page - 1) * perPage
    return String(getCountData + idx + 1)
  }

  private coloringStatus(
    status: string
  ): {
    text: string
    color: string
  } {
    let result = {
      text: '',
      color: '',
    }

    switch (status) {
      case 'COMPLETED':
        result = {
          text: 'Completed',
          color: 'success',
        }
        break
      case 'FAILED':
        result = {
          text: 'Failed',
          color: 'error-1',
        }
        break
      default:
        result = {
          text: 'Processed',
          color: 'warning',
        }
        break
    }
    return result
  }

  @Watch('controller.dataManageDataPayrollDetailList')
  setDataDetail(data: ManageDataPayrollDetail[]): void {
    this.detailData = data.map((item, index) => {
      let modeOpt = ''
      let shipmentIDOrSTT = ''
      let isAdjusted = ''

      if (item.shipmentId) {
        shipmentIDOrSTT = item.shipmentId
      } else {
        shipmentIDOrSTT = item.packageId || ''
      }

      if (item.hasAdjustment) {
        isAdjusted = EnumAdjusted.YA
      } else {
        isAdjusted = EnumAdjusted.TIDAK
      }

      if (item.mode) {
        const opt = this.modeFilterOptions.find(
          mode => mode.value === String(item.mode)
        )

        if (opt) {
          modeOpt = String(opt.label)
        }
      }

      return [
        this.tableCellMapper(
          this.indexTable(
            index,
            this.detailParameters.page,
            this.detailParameters.per_page
          ),
          '10%'
        ),
        this.tableCellMapper(modeOpt, '10%'),
        this.tableCellMapper(shipmentIDOrSTT, '20%'),
        this.tableCellMapper(
          this.formatDateTime(String(item.createdAt)),
          '20%'
        ),
        this.tableCellMapper(
          `Rp${Utils.currencyDigit(Number(item.amount))}`,
          '15%'
        ),
        this.tableCellMapper(isAdjusted, '25%'),
      ]
    })
  }

  @Watch('controller.dataManageDataPayrollHistoryList')
  setDataHistory(data: ManageDataPayrollHistory[]): void {
    this.historyData = data.map((item, index) => {
      return [
        this.tableCellMapper(
          this.indexTable(
            index,
            this.historyParameters.page,
            this.historyParameters.per_page
          ),
          '60px'
        ),
        this.tableCellMapper(
          this.formatDateTime(String(item.createdAt)),
          '220px'
        ),
        this.tableCellMapper(
          `Rp${Utils.currencyDigit(Number(item.amount))}`,
          '160px'
        ),
        this.tableCellMapper(
          `-Rp${Utils.currencyDigit(Number(item.adminFee))}`,
          '140px'
        ),
        this.tableCellMapper(
          `Rp${Utils.currencyDigit(Number(item.received))}`,
          '180px'
        ),
        this.tableCellMapper(String(item.bankName), '300px'),
        this.tableCellMapper(String(item.status), '144px'),
        this.tableCellMapper(item.description ? String(item.description) : '-', '280px'),
      ]
    })
  }
}
