import { UpdateManageDataPayrollRequestInterface } from '@/data/payload/contracts/ManageDataPayrollRequest'

export class UpdateManageDataPayrollApiRequest
  implements UpdateManageDataPayrollRequestInterface {
  public is_active?: boolean

  constructor(is_active?: boolean) {
    this.is_active = is_active
  }

  public toPayload(): string {
    const data = {
      is_active: this.is_active,
    }

    return JSON.stringify(data)
  }
}
