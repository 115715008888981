import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { ManageDataPayrollPresenter } from '@/app/ui/presenters/ManageDataPayrollPresenter'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  ManageDataPayroll,
  ManageDataPayrollDetail,
  ManageDataPayrollDetails,
  ManageDataPayrollHistories,
  ManageDataPayrollHistory,
  ManageDataPayrolls,
} from '@/domain/entities/ManageDataPayroll'
import { UpdateManageDataPayrollApiRequest } from '@/data/payload/api/ManageDataPayrollRequest'

interface ManageDataPayrollState {
  isLoading: boolean
  isLoadingDetail: boolean
  isLoadingHistory: boolean
  dataManageDataPayrollList: ManageDataPayroll[]
  dataManageDataPayrollDetailList: ManageDataPayrollDetail[]
  dataManageDataPayrollHistoryList: ManageDataPayrollHistory[]
  paginationData: Pagination
  detailPaginationData: Pagination
  historyPaginationData: Pagination
  isUpdateManageDataPayrollSuccess: boolean
}

interface IUpdatePayload {
  id: number
  is_active: boolean
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'manage-data-payroll',
})
class ManageDataPayrollController extends VuexModule
  implements ManageDataPayrollState {
  private presenter: ManageDataPayrollPresenter = container.resolve(
    ManageDataPayrollPresenter
  )
  public isLoading = false
  public isLoadingDetail = false
  public isLoadingHistory = false
  public dataManageDataPayrollList = [new ManageDataPayroll()]
  public dataManageDataPayrollDetailList = [new ManageDataPayrollDetail()]
  public dataManageDataPayrollHistoryList = [new ManageDataPayrollHistory()]
  public paginationData = new Pagination()
  public detailPaginationData = new Pagination()
  public historyPaginationData = new Pagination()
  public isUpdateManageDataPayrollSuccess = false

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res) this.setDataPayrollList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Data Penghasilan List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllDetail(payload: {
    id: number
    params: Record<string, string | number | boolean>
  }) {
    this.setLoadingDetail(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.presenter
      .getAllDetail(payload.id, formattedParams)
      .then(res => {
        if (res) this.setDataPayrollDetailList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Data Penghasilan Detail List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDetail(false)
      })
  }

  @Action({ rawError: true })
  public getAllHistory(payload: {
    id: number
    params: Record<string, string | number>
  }) {
    this.setLoadingHistory(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(payload.params),
      'snake_case'
    )

    this.presenter
      .getAllHistory(payload.id, formattedParams)
      .then(res => {
        if (res) this.setDataPayrollHistoryList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Data Penghasilan History List Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingHistory(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: IUpdatePayload) {
    this.setLoading(true)
    this.presenter
      .update(
        payload.id,
        new UpdateManageDataPayrollApiRequest(payload.is_active)
      )
      .then(res => {
        this.setUpdateManageDataPayrollSuccess(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Manage Data Payroll Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setUpdateManageDataPayrollSuccess(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingDetail(bool: boolean) {
    this.isLoadingDetail = bool
  }

  @Mutation
  private setLoadingHistory(bool: boolean) {
    this.isLoadingHistory = bool
  }

  @Mutation
  private setDataPayrollList(list: ManageDataPayrolls) {
    this.dataManageDataPayrollList = <ManageDataPayroll[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataPayrollDetailList(list: ManageDataPayrollDetails) {
    this.dataManageDataPayrollDetailList = <ManageDataPayrollDetail[]>list.data
    this.detailPaginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataPayrollHistoryList(list: ManageDataPayrollHistories) {
    this.dataManageDataPayrollHistoryList = <ManageDataPayrollHistory[]>(
      list.data
    )
    this.historyPaginationData = <Pagination>list.pagination
  }

  @Mutation
  public setUpdateManageDataPayrollSuccess(bool: boolean) {
    this.isUpdateManageDataPayrollSuccess = bool
  }
}

export default getModule(ManageDataPayrollController)
