export enum EnumSort {
  DESC = 'DESC',
  ASC = 'ASC'
}

export enum EnumMode {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY'
}

export enum EnumAdjusted {
  YA = 'YA',
  TIDAK = 'TIDAK'
}

export enum EnumTable {
  DETAIL_TABLE = 'DETAIL',
  HISTORY_TABLE = 'HISTORY'
}
